import axios from "axios"
import { API_URL } from "./constants"

export const getPriceById = async id => {
  try {
    const res = await axios.get(`${API_URL}/prices/${id}`)
    return res.data
  } catch (e) {
    console.error(e)
  }
}

export const getAllPrices = async ({ brand = "" }) => {
  try {
    const query = brand ? `?brand=${brand}` : ""
    const res = await axios.get(`${API_URL}/prices` + query)
    return res.data
  } catch (e) {
    console.error(e)
  }
}

import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { ChevronRightIcon } from "@heroicons/react/solid"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Table from "../components/Home/BrandsSection/Table"

import ModelSelect from "../components/Home/BrandsSection/ModelSelect"
import { getAllPrices } from "../services/pricesServices"

import { getBrandText } from "./data/pricesText"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const PriceByModel = props => {
  const { location } = props
  const { brand } = props.pageContext

  function compare(a, b) {
    if (a.model < b.model) {
      return -1
    }
    if (a.model > b.model) {
      return 1
    }
    return 0
  }

  const [data, setData] = useState(null)
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      let response = await getAllPrices({ brand: brand })

      setData(response)
      setLoading(false)
    }

    fetchData()
  }, [])

  const [selectedModel, setSelectedModel] = useState("Выберите модель")

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const model = params.get("m")

    setSelectedModel(model || "Выберите модель")
  }, [location.search])

  if (isLoading) return <p>Загрузка...</p>
  if (!data) return <p>Нет данных</p>

  return (
    <Layout path={location.pathname}>
      {
        // eslint-disable-next-line
        <SEO
          title={`Цена на катализатор ${brand}`}
          description={`Актуальная цена на катализатор от автомобиля ${brand}`}
        />
      }
      <div className="overflow-hidden bg-gray-100 pt-20 lg:pt-16">
        <section
          id="price"
          aria-labelledby="price-heading"
          className="mx-auto max-w-xl py-6 px-0 sm:py-24 md:px-12 lg:max-w-7xl"
        >
          <h1
            id="price-heading"
            className="text-center text-xl font-bold text-gray-900 sm:text-4xl"
          >
            Цены на катализаторы от {brand}
          </h1>

          <h2 className="px-1 pt-2 text-center text-base font-light md:px-12 md:text-2xl">
            Актуальные цены скупки компанией КаталикАвто
          </h2>

          <div className="mt-6 grid grid-cols-1 space-y-2 px-1 sm:grid-cols-3 sm:gap-x-6 sm:space-y-0">
            <nav className="hidden sm:flex" aria-label="Breadcrumb">
              <ol className="flex items-center space-x-4">
                <li>
                  <div className="flex">
                    <Link
                      to="/"
                      className="text-base font-medium text-gray-500 hover:text-gray-700"
                    >
                      Главная
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="flex items-center">
                    <ChevronRightIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <AnchorLink
                      to="/#brands"
                      className="ml-4 text-base font-medium text-gray-500 hover:text-gray-700"
                    >
                      Выбор марки авто
                    </AnchorLink>
                  </div>
                </li>
              </ol>
            </nav>
          </div>
          <ModelSelect
            selected={selectedModel}
            setSelected={setSelectedModel}
            models={data
              .filter(record => record.brand === brand)
              .map(record => record.model)}
          />
          <div id="price-list" className="pt-4">
            <Table
              data={data
                .sort(compare)
                .filter(
                  !["Выберите модель", "Все модели"].includes(selectedModel)
                    ? record => record.model === selectedModel
                    : record => record
                )}
            />
          </div>
          <div className="pr-2 text-sm font-light text-gray-700 md:text-base">
            *Данные о стоимости катализатора получены после отдельного помола в
            собственном цеху с использованием стендового прибора Niton
          </div>
          <div className="lg:text-md mt-12 space-y-4 rounded-md bg-white p-4 text-sm text-gray-700 shadow-sm">
            <h2>О марке автомобиля {brand}</h2>
            {getBrandText(brand)}
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default PriceByModel

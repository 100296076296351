import React, { useEffect, useState } from "react"

import { getPriceById } from "../../../services/pricesServices"
import { numberWithSpaces } from "../../helpers"

const PriceWrapper = ({ children }) => (
  <td className="whitespace-nowrap px-1 py-2 text-right text-xs font-medium lg:px-6 lg:text-sm">
    {children}
  </td>
)

const LoadingSpinner = () => (
  <div className="flex items-center justify-center transition duration-150 ease-in-out">
    <svg
      className="h-5 w-5 animate-spin text-orange-500"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  </div>
)
export default function Price({ id }) {
  const [data, setData] = useState(null)
  const [isLoading, setLoading] = useState(false)

  useEffect(() => setData(null), [id])

  const loadPrice = async () => {
    setLoading(true)
    const response = await getPriceById(id)

    setData(response)
    setLoading(false)
  }

  if (isLoading)
    return (
      <PriceWrapper>
        <LoadingSpinner />
      </PriceWrapper>
    )

  if (!data)
    return (
      <PriceWrapper>
        <button
          onClick={() => loadPrice()}
          className="text-orange-500 hover:text-orange-600"
        >
          Показать
        </button>
      </PriceWrapper>
    )

  return (
    <PriceWrapper>
      <div className="text-orange-500">{numberWithSpaces(data.price)} ₽</div>
    </PriceWrapper>
  )
}

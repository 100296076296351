import React from "react"

import Price from "./Price"

export default function Table({ data }) {
  return (
    <section className="" id="table">
      <div className="mx-auto max-w-full md:max-w-7xl">
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-white">
                    <tr>
                      <th
                        scope="col"
                        className="px-1 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500 lg:px-6"
                      >
                        Марка
                      </th>
                      <th
                        scope="col"
                        className="px-1 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500 lg:px-6"
                      >
                        Модель
                      </th>
                      <th
                        scope="col"
                        className="px-1 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500 lg:px-6"
                      >
                        Описание
                      </th>

                      <th
                        scope="col"
                        className="px-1 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500 lg:px-6 "
                      >
                        Цена
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((record, recordIdx) => (
                      <tr
                        key={`${record.brand}-${recordIdx}`}
                        className={`hover:bg-gray-100 
                            ${recordIdx % 2 === 0 ? "bg-white" : "bg-gray-50"}
                         `}
                      >
                        <td className="whitespace-nowrap px-1 py-1 text-xs font-medium text-gray-900 lg:py-2 lg:px-6 lg:text-sm">
                          {record.brand}
                        </td>
                        <td className="whitespace-nowrap px-1 py-1 text-xs text-gray-500 lg:py-2 lg:px-6 lg:text-sm">
                          {record.model}
                        </td>
                        <td className="px-1 py-1 text-xs text-gray-500 lg:py-2 lg:px-6 lg:text-sm">
                          {record.comment}
                        </td>
                        <Price id={record.id} />
                      </tr>
                    ))}
                  </tbody>

                  <tfoot className="w-full border-t border-gray-200 bg-white shadow">
                    <tr>
                      <td
                        colSpan="4"
                        className="w-full whitespace-nowrap px-2 py-2 text-sm font-medium text-orange-500 lg:px-6"
                      >
                        Всего {data.length}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
